// Here you can add other styles
.app-header {
  @media (max-width: 991.98px) {
    padding: 0 1rem 0 1rem;
  }
}
.app-header .nav-item {
  right: 0.5rem;
}

.sidebar {
  background-color: #e52f5e;
}

.sidebar .nav-link.active {
  background-color: #a21f41;
}

.sidebar .nav-link:hover {
  background-color: #a21f41;
}

.sidebar .nav-link .nav-icon {
  color: #fff;
}

.navbar-nav .nav-link {
  color: #fff;
}

.app-header.navbar {
  background-color: #a21f41;
  border-bottom: 0;
}

a {
  color: #a21f41;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #a21f41;
  background-color: #a21f41;
}

.btn-primary {
  color: #fff;
  background-color: #a21f41;
  border-color: #a21f41;
}

.card.bg-info .card-header {
  background-color: #e52f5e;
  border-color: #e52f5e;
}

.card.bg-primary .card-header {
  background-color: #a21f41;
  border-color: #a21f41;
}

.ml-auto a {
  color: #e52f5e;
}

.sidebar .nav-link.active .nav-icon {
  color: #fff;
}

.app-header .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23fff' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}

.app-header {
  border-bottom: none;
}

body {
  font-family: "JosefinSans", serif;
}

.sidebar .sidebar-minimizer::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.sidebar .sidebar-minimizer:hover::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%2373818f' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.table th, .table td {
  white-space: nowrap;
  vertical-align: middle;
}

.app-header .navbar-brand {
  overflow: hidden;
}

.flatpickr-calendar.inline{
  margin: 0 auto;
}

.btn-banner {
  max-height: 30px;
  border-radius: 6px;
  color: white;
  background: #f86c6b;
  border: none;
}


.tooltip--topping-selected {
  .tooltip-inner {
    width: auto;
    max-width: unset;
    text-align: left;
    padding: 10px;

    ul {
      margin: 0;
      padding-left: 20px;

      li {
        white-space: nowrap;
        font-family: JosefinSans;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}